import { useEffect, useMemo } from "react"
import { styled } from "@mui/material/styles"
import { Paper, List } from "@mui/material"

import * as shared from "probuild-shared"

import SupportAgentPageLayout from "components/views/layouts/SupportAgentPageLayout"
import LoadingView from "components/views/LoadingView"
import TeamSelectorItemView from "components/views/team/TeamSelectorItemView"
import ErrorView from "components/views/ErrorView"
import EmptyView from "components/views/EmptyView"
import PageHeader from "components/views/generic/PageHeader"
import usePresenter from "model/presenter/usePresenter"
import useDependencyProvider from "model/utils/useDependencyProvider"

const PaddedDiv = styled("div")({
  paddingTop: 16,
})

const SupportAgentTeamSelectorPage = () => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider) {
      return new shared.com.probuildsoftware.probuild.library.team.TeamSelectorPresenter(
        dependencyProvider
      )
    }
  }, [dependencyProvider])
  const phoneNumber = new URLSearchParams(window.location.search).get(
    "phoneNumber"
  )
  useEffect(() => {
    if (phoneNumber) {
      presenter?.input.loadTeams(phoneNumber)
    }
  }, [phoneNumber, presenter?.input])
  const [viewData] = usePresenter(presenter)
  return (
    <SupportAgentPageLayout>
      <PaddedDiv>
        <PageHeader title={viewData?.title} subtitle={viewData?.subtitle} />
        {viewData?.status?.isLoaded === true ? (
          <List component={Paper} disablePadding={true}>
            {viewData?.items?.asJsReadonlyArrayView()?.map((item) => {
              return <TeamSelectorItemView key={item.viewKey} item={item} />
            })}
            <EmptyView icon={null} viewData={viewData?.empty} />
            {viewData?.status.isErrored === true && (
              <ErrorView description={viewData?.errorDescription || null} />
            )}
          </List>
        ) : (
          <LoadingView />
        )}
      </PaddedDiv>
    </SupportAgentPageLayout>
  )
}

export default SupportAgentTeamSelectorPage
