import { styled } from "@mui/material/styles"
import {
  Avatar,
  Typography,
  ListItemButton,
  Box,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import { Link } from "react-router-dom"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
}))

const StyledContentDiv = styled("div")({
  display: "flex",
  flexBasis: "100%",
  justifyContent: "space-between",
})

const TeamNameContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
}))

const InfoContentDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const TeamSelectorItemView = ({
  item,
}: {
  item?: shared.com.probuildsoftware.probuild.library.team.data.view.TeamSelectorItemViewData
}) => {
  const teamLogoPath = item?.logo?.info.path
    ? `/api/storage/${item?.logo?.info.path}?resize=200x200&jpegQuality=80&rotate=true`
    : "/buildingIcon.png"
  const oldWebportalPath = `https://webportal-dot-${process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID}.appspot.com/webportal/teams/${item?.teamKey}`
  const newWebportalPath = (item?.teamKey && paths.team(item?.teamKey)) || ""
  return (
    <>
      <ListItemButton
        component={Link}
        to={newWebportalPath}
        sx={{ opacity: item?.deletedAt && 0.7 }}
      >
        <StyledBox>
          <StyledContentDiv>
            <TeamNameContainerDiv>
              <Avatar
                variant="rounded"
                src={teamLogoPath}
                sx={{ width: 64, height: 64 }}
              />
              <InfoContentDiv>
                <Typography fontWeight="bold">
                  {item?.teamName || ""}
                </Typography>
                <Typography>{item?.userName || ""}</Typography>
                {item?.deletedAt ? (
                  <Typography color="red" fontStyle="italic">
                    {item?.deletedAt}
                  </Typography>
                ) : (
                  <Typography fontStyle="italic">
                    {item?.lastOnline || ""}
                  </Typography>
                )}
              </InfoContentDiv>
            </TeamNameContainerDiv>
            <Tooltip title="Old Portal">
              <IconButton
                component={Link}
                to={oldWebportalPath}
                sx={{ height: "fit-content", alignSelf: "center" }}
              >
                <WebAssetIcon />
              </IconButton>
            </Tooltip>
          </StyledContentDiv>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default TeamSelectorItemView
